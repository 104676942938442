import { Injectable } from '@angular/core';
import { DocumentData } from '@angular/fire/compat/firestore';
import {
  CollectionReference,
  DocumentReference,
  Firestore,
  collection,
  collectionData,
  doc,
  docData,
  onSnapshot,
  query,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { PartnerCompany } from '../../finance-manager/models/partner-company.model';
import { EconSettlementFile, JBankSettlementFile } from '../../finance-manager/models/partner.model';
import { AppConfig } from '../models/app-config.model';
import { Area } from '../models/area.model';
import { CreditNote, PartnerSettlement } from '../models/partner-company.model';

@Injectable({ providedIn: 'any' })
export class ConsoleFirestoreService {
  constructor(private afs: Firestore) {}

  listAreas(): Observable<Area[]> {
    return collectionData<Area>(
      query<Area, DocumentData>(collection(this.afs, '/shared/current/area') as CollectionReference<Area>),
    );
  }

  getPartnerCompany(id: string): Observable<PartnerCompany> {
    return docData<PartnerCompany>(
      doc(this.afs, '/role/finance_manager/partner_company', id) as DocumentReference<PartnerCompany>,
    );
  }

  getPartnerCreditNote(settlementId: string): Observable<CreditNote> {
    return docData<CreditNote>(
      doc(this.afs, '/role/finance_manager/credit_note', settlementId) as DocumentReference<CreditNote>,
    );
  }

  listJBankSettlementFiles(): Observable<JBankSettlementFile[]> {
    return collectionData<JBankSettlementFile>(
      query<JBankSettlementFile, DocumentData>(
        collection(
          this.afs,
          '/role/finance_manager/j_bank_settlement_file',
        ) as CollectionReference<JBankSettlementFile>,
      ),
    );
  }

  listEconSettlementFiles(): Observable<EconSettlementFile[]> {
    return collectionData<EconSettlementFile>(
      query<EconSettlementFile, DocumentData>(
        collection(this.afs, '/role/finance_manager/econ_settlement_file') as CollectionReference<EconSettlementFile>,
      ),
    );
  }

  listPartnerCompanySettlements(partnerCompanyId: string): Observable<PartnerSettlement[]> {
    return collectionData<PartnerSettlement>(
      query<PartnerSettlement, DocumentData>(
        collection(
          this.afs,
          '/role/finance_manager/partner_company/' + partnerCompanyId + '/settlement',
        ) as CollectionReference<PartnerSettlement>,
      ),
    );
  }

  watchAppConfig(): Observable<AppConfig> {
    return new Observable(observer => {
      const appConfigCollectionRef = collection(this.afs, 'app_config');
      const q = query(appConfigCollectionRef);

      const unsubscribe = onSnapshot(
        q,
        snapshot => {
          const appConfigArray = snapshot.docs
            .filter(docSnap => docSnap.exists)
            .reduce((prev, curr) => ({ ...prev, [curr.id]: curr.data() }), {});
          observer.next(appConfigArray);
        },
        error => {
          observer.error(error);
        },
      );

      return () => unsubscribe();
    });
  }
}
